import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["metaTable", "rowTemplate", "searchResults", "searchResultTemplate", "productId", "productInfo", "stockCode", "searchResultsContainer"]

  static values = {
    accountId: String
  }

  connect() {
    if (this.productIdTarget.value.length === 0) {
      this.searchResultsContainerTarget.classList.remove("hidden");
      this.productInfoTarget.classList.add("hidden");
      this.stockCodeTarget.dispatchEvent(new Event('input', { bubbles: true }));
    } else {
      this.searchResultsContainerTarget.classList.add("hidden");
      this.productInfoTarget.classList.remove("hidden");
    }
  }

  addRow() {
    const newRow = this.rowTemplateTarget.innerHTML
    this.metaTableTarget.insertAdjacentHTML("beforeend", newRow)
  }

  removeRow(event) {
    event.target.closest("tr").remove()
  }

  searchStockCodes(event) {
    this.productInfoTarget.classList.add("hidden");
    this.searchResultsContainerTarget.classList.remove("hidden");
    const query = event.target.value
    if (query.length < 2) {
      this.searchResultsTarget.innerHTML = ""
      return
    }

    this.searchResultsTarget.innerHTML = "<p>Searching...</p>"

    fetch(`/a/${this.accountIdValue}/stock_codes/search?query=${encodeURIComponent(query)}`, {
      headers: { "Accept": "application/json" }
    })
    .then(response => response.json())
    .then(data => {
      this.displayResults(data)
    })
  }

  displayResults(data) {
    this.searchResultsTarget.innerHTML = "";
    data.forEach(result => {
      const resultElement = this.searchResultTemplateTarget.content.cloneNode(true);
      const row = resultElement.querySelector('tr');  // Get the tr element
      row.setAttribute("data-product-id", result.product_id);
      const spans = row.querySelectorAll('[data-field]');
      spans.forEach(span => {
        const field = span.dataset.field;
        span.textContent = result[field] || '';
      });
      this.searchResultsTarget.appendChild(resultElement);
    });

    if (data.length === 1 && data[0].code.toLowerCase() === this.stockCodeTarget.value.toLowerCase()) {
      this.searchResultsTarget.querySelector('button[data-action="click->inventory-form#selectStockCode"]').classList.toggle("hidden", true);
    }
  }

  selectStockCode(event) {
    this.stockCodeTarget.value = event.target.closest("tr").querySelector('[data-field="code"]').textContent;
    this.stockCodeTarget.dispatchEvent(new Event('input', { bubbles: true }));
  }
}